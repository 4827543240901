<template>
  <div>
    <div class="box">
      <div>
        <div class="text-right">
          <el-select v-model="labelValue" placeholder="全部" clearable @change="onLoad" @clear="onLoad">
            <el-option
              v-for="item in questionLabel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <div class="input">
            <el-input
              v-model="searchWord"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              clearable
              @input="onLoad"
            />
          </div>
          <el-button type="primary" @click="askQuestion">提问</el-button>
        </div>

        <div style="min-height: 500px">

          <section v-for="(item,index) in questionList" :key="index" class="questionEvery">
            <div class="title">
              <el-tooltip class="item" effect="dark" :content="item.title" placement="top" :disabled="!showTip">
                <span class="title-c" style="color:#2d64b3;font-weight:600;font-size: 20px;cursor: pointer;" @mouseover="textRange" @click="todetail(item)"> {{ item.title === null ? '暂无标题' : item.title }}</span>
              </el-tooltip>
              <header>
                <span style="color: #333333">{{ item.isAnonymous == 0 ? item.studentName : '匿名提问' }}</span>
                <span>{{
                  questionLabel[item.label].label
                }}</span>
              </header>
            </div>
            <div class="time">{{ item.createTime }}</div>
            <div class="questionContent">
              <!--              <p>-->
              <!--                &lt;!&ndash;            <span >...查看全文</span>&ndash;&gt;-->
              <!--                {{ item.content }}-->
              <!--              </p>-->
              <!--                <div class="readTheFullArticle" @click="todetail(item)">阅读全文</div>-->
              {{ item.content }}
            </div>
            <div class="mainBody-image">
              <div v-for="(every,index) in item.pictures" :key="index" style="margin: 0 10px 10px 0">
                <el-image :src="every" style="width: 214px;height: 138px" fit="cover" @click="showBigPicture(every)">
                  <div slot="error" class="image-slot">
                    <!--                    错误的占位符-->
                  </div>
                </el-image>
              </div>
            </div>

            <div class="answer-c">
              <div style="font-size: 16px;color: #4392fd;margin: 10px 0">最新回复</div>
              <div v-if="item.replyList.length>0">
                <div v-for="(every,replyIndex) in item.replyList" :key="replyIndex">
                  <div style="display: flex;border-top: 1px solid #E7ECF2">
                    <div v-if="every.type===0" class="answer-name" style="color: #07479D;">{{
                      every.replyName === null || every.replyName === '' ? '学生' : every.replyName
                    }}回复：
                    </div>
                    <div v-if="every.type===1" class="answer-name" style="color: #894306">
                      <!--                  {{item.replyList[0].replyName === null || item.replyList[0].replyName === '' ? '老师' : item.replyList[0].replyName}}-->
                      老师回复：
                    </div>
                    <p>{{ every.content }}</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div style="margin: 20px;font-size: 14px;color: #666666">暂无回复哦~</div>
              </div>
            </div>
          </section>
          <el-empty v-if="questionList.length===0" description="暂无数据" style="padding: 160px 0" :image="require('@/assets/images/empty.png')" />
        </div>
        <div v-if="questionList.length>0" class="changePage">
          <el-pagination
            background
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />

        </div>
      </div>
      <!-- 提问-->
      <el-dialog
        title="发表提问"
        :visible.sync="dialogVisible"
        width="540px"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <div class="text-left">选择标签：
          <el-select v-model="selectedLabel" placeholder="请选择">
            <el-option
              v-for="item in questionLabel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <span style="float:right;">
            <el-checkbox v-model="checked">匿名</el-checkbox>
          </span>
        </div>
        <div class="text-left title">
          <span style="line-height: 40px">输入标题：</span>
          <span>
            <el-input
              v-model="submitTitle"
              placeholder="请输入标题"
              maxlength="30"
              :show-word-limit="true"
              clearable
            />
          </span>
        </div>
        <div style="text-align: left">
          <Editor v-model="textarea" :height="250" style="width: 100%" :data="{type:6}" :value="textarea" />
        </div>
        <div class="send">
          <el-button type="primary" round @click="submitQuestion">发布</el-button>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="showPicture">
        <img width="100%" :src="currentPic">
      </el-dialog>
    </div>

    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import { mapState } from 'vuex'
import { getQuestionList, subQuestion } from '../../api/answer/index'
import Editor from '../../components/Editor'
export default {
  name: 'AnswerQuestions',
  components: {
    indexBottom,
    Editor
  },
  created() {
    this.onLoad()
  },
  computed: {
    ...mapState({
      questionLabel: state => state.dict.dictTree.question_label['arr']
    })
  },
  data() {
    return {
      labelValue: '',
      selectedLabel: '',
      searchWord: '',
      dialogVisible: false,
      currentPage: 1,
      pageSize: 5,
      total: 0,
      questionList: [],
      checked: true,
      input: '',
      submitTitle: '',
      textarea: '',
      showTip: false,
      pictures: [
        'https://teaching-management.nos-eastchina1.126.net/class/paperscore/db1435c2f54a4564ad53be56dbf0094a-水瓶.png',
        'https://teaching-management.nos-eastchina1.126.net/class/paperscore/db1435c2f54a4564ad53be56dbf0094a-水瓶.png',
        'https://teaching-management.nos-eastchina1.126.net/class/paperscore/db1435c2f54a4564ad53be56dbf0094a-水瓶.png'
      ],
      // 展示大图的弹框状态
      showPicture: false,
      // 当前展示的大图地址
      currentPic: ''

    }
  },
  methods: {
    onLoad() {
      const obj = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        label: this.labelValue,
        content: this.searchWord
      }
      // 查询疑问列表
      getQuestionList(obj).then(res => {
        console.log(res)
        this.questionList = res.rows
        this.total = res.total
      })
    },
    // 展示大图的弹框
    showBigPicture(item) {
      this.showPicture = true
      this.currentPic = item
    },
    todetail(item) {
      console.log(item)
      this.$router.push(
        {
          path: '/answerQuestionsDetail',
          query: {
            id: item.id
          }
        })
    },
    // 提问弹框
    askQuestion() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      this.textarea = ''
      this.submitTitle = ''
      this.selectedLabel = ''
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // this.currentPn=this.pageSize*(this.currentPage-1)+1
      this.onLoad()
    },
    submitQuestion() {
      console.log(this.checked)
      console.log(this.selectedLabel)
      console.log(this.submitTitle)
      console.log(this.textarea)
      if (this.selectedLabel === '') {
        this.$message.error('请选择一个标签')
        return
      }
      if (this.submitTitle === '') {
        this.$message.error('请填写标题')
        return
      }
      if (this.textarea === '') {
        this.$message.error('请填写内容')
        return
      }
      const obj = {
        content: this.textarea,
        label: this.selectedLabel,
        title: this.submitTitle,
        isAnonymous: this.checked ? 1 : 0
      }
      subQuestion(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success('发布成功')
          this.dialogVisible = false
          this.selectedLabel = ''
          this.submitTitle = ''
          this.textarea = ''
          this.checked = true
          this.onLoad()
        }
      })
    },
    textRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showTip = rangeWidth > targetW
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-button.is-round {
  padding: 12px 50px;
}

.send {
  margin-top: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  span:nth-of-type(2) {
    flex-grow: 1;
  }
}

/deep/ .el-dialog__title {
  color: #66b1ff;
}

.text-right {
  padding-top: 60px;
  margin: 20px 0;
}

section {
  color: rgba(48, 48, 48, 1);
  text-align: left;
  margin: 20px 0;
  padding: 10px 20px 20px;
  border-radius: 10px;
  background: white;

  .title {
    margin-bottom: 10px;

    header {
      float: right;

      span {
        font-size: 14px;
        color: #aaa;
        margin-right: 20px;
      }

      span:nth-of-type(2) {
        color: #66b1ff;
        padding: 5px;
        border: 2px solid #409EFF;
        border-radius: 6px;

      }
    }
  }

  .time {
    color: #aaa;
    font-size: 14px;
    padding-bottom: 10px;
    border-bottom: 2px solid #E7ECF2;
  }
  /* 难题解答 开始 */
  .questionContent {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    margin: 20px 0;
    height: 60px;
    color: rgba(48, 48, 48, 1);
    font-size: 18px;
    line-height: 30px;
    position: relative;
    &::before{
      content: '';
      float: right;
      width: 0;
      height: calc(100% - 30px);
    }
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #fff;
    }
    >.readTheFullArticle {
      float: right;
      color: rgba(67, 146, 253, 1);
      font-size: 18px;
      height: 30px;
      clear: both;
      margin-right: 243px;
      cursor: pointer;
    }
  }
  /* 难题解答 结束 */
  .mainBody-image{
    display: flex;
    position: relative;
  }
  p {
    font-size: 14px;
    margin: 20px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    position: relative;
    width: 950px;
    color: #717171;
    span {
      position: absolute;
      width: 200px;
      right: 0px;
      bottom: 0px;
      color: #66b1ff;
      background: white;
      cursor: pointer;
    }
  }
}

.input {
  display: inline-block;
  width: 220px;
  margin: 0 20px;
}

.changePage {
  text-align: right;
  padding-bottom: 20px;
}
.title-c{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 900px;
}
//.questionEvery{
//  .firstDiv:hover{
//    tranform:scale(2)
//  }
//}
//.questionEvery:hover{
//  position: relative;
//  bottom: 3px;
//}
.answer-c{
  background-color: #f6f9fe;
  padding: 10px;
  .answer-name{
    font-family:"Microsoft YaHei";
    margin: 20px 14px 0;
    font-size: 14px;
    width: 70px;
  }
}

</style>
